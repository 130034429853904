import revive_payload_client_wSknega3HP from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pOOsbGx2QJ from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jDTi8Fr0Lm from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4T36ETZOc1 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BQzNvTSSh0 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GyMwU9Qmg5 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_FdfC2OSTig from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_dD0juC1Cfe from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_tWPA2Vt6mz from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.24.0_rollup@4.39.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_G7vjUvtwFU from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.24.0_rollup@4.39.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_KIKASSp8rR from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.24.0_rollup@4.39.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_dkveMi8zC4 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_PJKKzkKd3J from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.2.5_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_UqiYW4R153 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@types+node@22.10.1_graphql-sock@1.0.1_graphql-tag@2.12.6_typescript@5.8.3/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/layers/base/plugins/sentry.client.ts";
import widget_mode_X6BjB4n2GO from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/layers/base/plugins/widget-mode.ts";
import google_publisher_tag_9jDGgApxI5 from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/layers/portal/plugins/google-publisher-tag.ts";
import ssg_detect_xhc3i73cxU from "/home/forge/deployments/kinoheld/dev/2025-04-11_15-53-08_cf3c4b4/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_eslint@9.24.0_rollup@4.39.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_wSknega3HP,
  unhead_pOOsbGx2QJ,
  router_jDTi8Fr0Lm,
  payload_client_4T36ETZOc1,
  navigation_repaint_client_BQzNvTSSh0,
  check_outdated_build_client_GyMwU9Qmg5,
  view_transitions_client_FdfC2OSTig,
  chunk_reload_client_dD0juC1Cfe,
  switch_locale_path_ssr_tWPA2Vt6mz,
  route_locale_detect_G7vjUvtwFU,
  i18n_KIKASSp8rR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dkveMi8zC4,
  formkitPlugin_pZqjah0RUG,
  plugin_PJKKzkKd3J,
  plugin_UqiYW4R153,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  widget_mode_X6BjB4n2GO,
  google_publisher_tag_9jDGgApxI5,
  ssg_detect_xhc3i73cxU
]